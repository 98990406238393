import React from 'react'
import Helmet from 'react-helmet'

import OrganizationContactForm from '../../components/OrganizationContactForm/OrganizationContactForm'

const TrialOrganizationIndex = () => (
  <>
    <Helmet title={'Ovation VR | Trial Organization'}></Helmet>
    <OrganizationContactForm />
  </>
)

export default TrialOrganizationIndex
