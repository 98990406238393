import React from 'react'
import axios from 'axios'

import './OrganizationContactForm.scss'

import { ABOUT_US_OPTIONS, FORM_SPREE_ENDPOINT } from '../../constants/constants.js'

export default class OrganizationContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.formSubmit = this.formSubmit.bind(this)
  }

  formRefSubmit() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-contact-submit-form-trial-application'
    })
    setTimeout(() => {
      // give gtm time to update before redirecting page
      this.formRef.submit()
    }, 1000)
  }

  formSubmit(e) {
    e.preventDefault()
    this.emailInput.value = this.emailInput.value.trim()
    this.firstNameInput.value = this.firstNameInput.value.trim()
    this.lastNameInput.value = this.lastNameInput.value.trim()
    this.phoneInput.value = this.phoneInput.value.trim()

    // const urlMailChimp = 'https://portal.ovationvr.com/api/v1/mailchimp/member/create'
    const urlMailChimp = 'https://app.prod.ovationvr.com/api/v1/mailchimp/member/create'
    const urlBrevo = 'https://app.prod.ovationvr.com/api/v1/brevo/member/create'
    if (this.mailingListCheckbox.checked) {
      axios
        .all([
          axios.post(urlMailChimp, {
            email: this.emailInput.value,
            firstName: this.firstNameInput.value,
            lastName: this.lastNameInput.value
          }),
          axios.post(urlBrevo, {
            email: this.emailInput.value,
            firstName: this.firstNameInput.value,
            lastName: this.lastNameInput.value
          })
        ])
        .then(() => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'gtm-newsletter-add-contact-trial-application-form'
          })
          // Finish submitting form - Wait for promise to resolve before redirecting to captcha page
          this.formRefSubmit()
        })
        .catch((err) => {
          console.log('err', err)
          // Finish submitting form - Wait for promise to reject before redirecting to captcha page
          this.formRefSubmit()
        })
    } else {
      // Finish submitting form
      this.formRefSubmit()
    }
  }

  render() {
    return (
      <div className="organization-contact-form">
        <div className="organization-contact-header">
          <h2>Let's get you started</h2>
          <h4>New organizations receive a 30-day free trial</h4>
        </div>
        <div className="organization-contact-form-inner">
          <form
            className="holder"
            method="POST"
            action={FORM_SPREE_ENDPOINT}
            ref={(input) => {
              this.formRef = input
            }}
            onSubmit={(e) => this.formSubmit(e)}
          >
            <input name="_subject" style={{ display: 'none' }} value="Organization Contact Submission" readOnly></input>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputFirstName">First Name</label>
                <input
                  name="FirstName"
                  type="text"
                  required
                  className="form-control"
                  id="inputFirstName"
                  placeholder=""
                  ref={(input) => {
                    this.firstNameInput = input
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputLastName">Last Name</label>
                <input
                  name="LastName"
                  type="text"
                  required
                  className="form-control"
                  id="inputLastName"
                  placeholder=""
                  ref={(input) => {
                    this.lastNameInput = input
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputOrganization">Organization</label>
              <input
                name="Organization"
                type="text"
                required
                className="form-control"
                id="inputOrganization"
                placeholder=""
              />
            </div>
            <div className="form-group">
              <div className="form-check">
                <input
                  name="NonprofitOrSchool"
                  value="Yes"
                  className="form-check-input"
                  type="checkbox"
                  id="organizationCheck"
                />
                <label className="form-check-label" htmlFor="organizationCheck">
                  Organization is a school or NPO
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail">Organization Email Address</label>
              <input
                name="Email"
                type="email"
                required
                className="form-control"
                id="inputEmail"
                placeholder=""
                ref={(input) => {
                  this.emailInput = input
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPhone">Phone Number (Optional)</label>
              <input
                name="Phone"
                type="phone"
                className="form-control"
                id="inputPhone"
                placeholder=""
                ref={(input) => {
                  this.phoneInput = input
                }}
              />
            </div>
            <div className="form-group">
              <div className="form-check">
                <input name="CallMe" value="Yes" className="form-check-input" type="checkbox" id="phoneCheck" />
                <label className="form-check-label" htmlFor="phoneCheck">
                  Please call me. I have questions
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="messageTextarea">Message (optional)</label>
              <textarea name="Message" className="form-control" id="messageTextarea" rows="3" placeholder=""></textarea>
            </div>
            <div className="form-group">
              <label className="form-label-ovation">How did you hear about us?</label>
              <br />
              <select name="aboutUsOption" className="form-select-ovation">
                {ABOUT_US_OPTIONS.map(({ value, name }, index) => (
                  <option value={value} key={index}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <div className="form-check">
                <input
                  name="signUpForEmailList"
                  value="Yes"
                  className="form-check-input"
                  type="checkbox"
                  id="signUpForEmailList"
                  ref={(input) => {
                    this.mailingListCheckbox = input
                  }}
                />
                <label className="form-check-label" htmlFor="signUpForEmailList">
                  Sign Up for Ovation email updates
                </label>
              </div>
            </div>
            <div className="organization-ov-contact-submit">
              <button type="submit" className="organization-ov-contact-button">
                Submit
              </button>
              <div>
                <b>We’ll respond as soon as we can.</b>
                <br />
                Be sure to check your spam folder if you haven’t heard back from us.
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
